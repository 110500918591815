import classNames from 'classnames';
import { graphql, StaticQuery, Link } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

export const QuestionsImages = {
	DR_KROKOWSKI: 'dr_krokowski',
	DR_FUELLER: 'dr_fueller',
};

const Questions = ({ className, title = '', prefix = null, doctor = null }) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					dr_krokowski: file(
						relativePath: { eq: "team/dr_krokowski.jpg" }
					) {
						childImageSharp {
							fixed(width: 100, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}

					dr_fueller: file(
						relativePath: { eq: "team/dr_fueller.jpg" }
					) {
						childImageSharp {
							fixed(width: 100, quality: 100) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			`}
			render={(data) => {
				return (
					<div className="clearfix">
						<p className={classNames(className)}>
							{doctor && (
								<Img
									className="float-left mr-2 mb-4"
									fixed={data[doctor].childImageSharp.fixed}
								/>
							)}
							Sie haben konkrete Fragen zu unserem
							Behandlungsspektrum rund{' '}
							{prefix !== null ? prefix : 'um die'} {title}?
							<br />
							Rufen Sie uns einfach an oder nutzen Sie unser{' '}
							<Link
								to={'/kontakt'}
								className="text-blue hover:text-blue-dark"
							>
								Kontaktformular
							</Link>
							, wir beraten Sie gerne!
						</p>
					</div>
				);
			}}
		/>
	);
};

export default Questions;
