import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled(Link).attrs({
	className: 'text-blue no-underline',
})`
	height: 100%;

	:hover {
		.text {
			background: #f1f5f8;
		}
	}
`;

const Teaser = ({ to, title, image }) => (
	<StyledLink
		to={to}
		className="flex flex-col block pointer:cursor border border-grey-light"
	>
		<div>{image}</div>
		<div className="flex-1 text font-bold px-4 py-2 flex items-center justify-between bg-white border border-grey-light border-b-0 border-l-0 border-r-0">
			<span>{title}</span>
			<FontAwesomeIcon size="1x" icon={faChevronRight} />
		</div>
	</StyledLink>
);

export default Teaser;
