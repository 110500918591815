import React from 'react';
import Column from '../components/Column';
import Grid from '../components/Grid';
import ImageTeaserAkne from '../components/images/ImageTeaserAkne';
import ImageTeaserHaarausfall from '../components/images/ImageTeaserHaarausfall';
import ImageTeaserHautkrebs from '../components/images/ImageTeaserHautkrebs';
import ImageTeaserNagelpilz from '../components/images/ImageTeaserNagelpilz';
import ImageTeaserRosacea from '../components/images/ImageTeaserRosacea';
import ImageTeaserVenenleiden from '../components/images/ImageTeaserVenenleiden';
import ImageTeaserWarzen from '../components/images/ImageTeaserWarzen';
import Layout from '../components/layout';
import Questions from '../components/Questions';
import Teaser from '../components/teaser';

const MedizinischeDermatologie = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Medizinische Dermatologie"
			noSlider
			{...props}
		>
			<Questions title="Medizinische Dermatologie" />
			<div className="mt-12" />
			<Grid className="pt-4">
				<Column md="1/2">
					<Teaser
						to="/medizinische-dermatologie/hautkrebsvorsorge"
						title="Hautkrebs"
						image={<ImageTeaserHautkrebs />}
					/>
				</Column>
				<Column md="1/2">
					<Teaser
						to="/medizinische-dermatologie/warzenbehandlung"
						title="Warzen"
						image={<ImageTeaserWarzen />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/medizinische-dermatologie/akne-und-aknenarben"
						title="Akne"
						image={<ImageTeaserAkne />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/medizinische-dermatologie/nagelpilzerkrankungen"
						title="Nagelpilz"
						image={<ImageTeaserNagelpilz />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/medizinische-dermatologie/rosacea-und-couperose"
						title="Rosacea / Couperose"
						image={<ImageTeaserRosacea />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/medizinische-dermatologie/haarausfall-bei-mann-und-frau"
						title="Haarausfall"
						image={<ImageTeaserHaarausfall />}
					/>
				</Column>
				<Column md="1/2" className="mt-6">
					<Teaser
						to="/medizinische-dermatologie/venenleiden"
						title="Venenleiden"
						image={<ImageTeaserVenenleiden />}
					/>
				</Column>
			</Grid>
		</Layout>
	);
};

export default MedizinischeDermatologie;
