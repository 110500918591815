import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const ImageTeaserRosacea = ({ file }) => (
	<StaticQuery
		query={graphql`
			query {
				image: file(relativePath: { eq: "teaser/rosacea.jpeg" }) {
					childImageSharp {
						fluid(maxWidth: 371, maxHeight: 246) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={(data) => <Img fluid={data.image.childImageSharp.fluid} />}
	/>
);
export default ImageTeaserRosacea;
